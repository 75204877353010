import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as Clock } from "assets/images/clock.svg";
import { ReactComponent as Monitor } from "assets/images/monitor.svg";
import { ReactComponent as CircleCheckedGreen } from "assets/images/circle-checked-green.svg";
import Colors from "constants/colors";

import ReactTooltip from "react-tooltip";
import {
  Card,
  CardWrapper,
  BottomContainer,
  Title,
  VerticalLine,
  TitleContainer,
  ArrowRight,
  Italic,
  LeftBottom,
  MobileOnly,
  CalculatorStyledIcon,
  DictionaryStyledIcon,
} from "./styles";
import { Props } from "./types";

const Assessment = ({ status, name, time, type, id }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const assessmentName = useMemo(() => {
    const [firstWord, ...words] = name.split(" ");

    if (words.length >= 1) {
      const newWords = [firstWord.concat("\n"), ...words];

      return newWords.join(" ");
    }

    return name;
  }, [name]);

  const handleNavigate = () => {
    history.push(`/test-instruction/${type}/${id}`);
  };

  const notAvailableText = useMemo(() => {
    if (status === "not-available" && type === "verbal2") {
      return t("student.firstCompletePart_1");
    }

    if (status === "not-available" && type === "verbal3") {
      return t("student.firstCompletePart_2");
    }

    return t("student.notAvailable");
  }, [status, type, t]);

  return (
    <CardWrapper
      onClick={status === "available" ? handleNavigate : undefined}
      flexOrder={status === "not-available" ? 2 : 1}
    >
      <Card status={status} type={type}>
        <TitleContainer>
          <VerticalLine type={type} />
          <Title>{assessmentName}</Title>
          {type === "abstract" && (
            <MobileOnly>
              <Monitor />
            </MobileOnly>
          )}
          <ReactTooltip
            place="top"
            type="light"
            effect="float"
            border
            borderColor={Colors.denim}
            id="calculatorIcon"
          >
            <span>
              {t("student.calculatorIconTooltip")} <br />
            </span>
          </ReactTooltip>
          <CalculatorStyledIcon
            type={type}
            data-tip
            data-for="calculatorIcon"
          />
          <ReactTooltip
            place="top"
            type="light"
            effect="float"
            border
            borderColor={Colors.denim}
            id="dictionaryIcon"
          >
            <span>
              {t("student.dictionaryIconTooltip")} <br />
            </span>
          </ReactTooltip>
          <DictionaryStyledIcon
            type={type}
            data-tip
            data-for="dictionaryIcon"
          />
        </TitleContainer>
        <BottomContainer>
          {status === "finished" ? (
            <LeftBottom>
              <CircleCheckedGreen style={{ marginRight: "0.6rem" }} />
              <span style={{ color: Colors.oceanGreen }}>
                {t("student.completed")}
              </span>
            </LeftBottom>
          ) : (
            <LeftBottom>
              <Clock style={{ marginRight: "0.6rem" }} />
              <span style={{ color: Colors.shuttleGray }}>
                {Number(time)
                  ? `${time} ${t("student.minutes")}`
                  : t("student.noLimit")}
              </span>
            </LeftBottom>
          )}
          {status === "available" && <ArrowRight type={type} />}
          {status === "not-available" && <Italic>{notAvailableText}</Italic>}
        </BottomContainer>
      </Card>
    </CardWrapper>
  );
};

export default memo(Assessment);
