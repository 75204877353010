import styled from "styled-components";

export const Wrapper = styled.div`
  flex-direction: column;
  gap: 8px;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1.2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const DownloadButton = styled.button`
  display: flex;
  margin-top: 1.2rem;
  width: 11rem;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 1.4rem;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.geyser};
  height: 3.6rem;
  gap: 8px;
  border-radius: 0.4rem;
  cursor: pointer;

  &:last-child {
    width: 9rem;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.manatee};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.athensGray};
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
