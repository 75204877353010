import React, { useState } from "react";

import Title from "components/atoms/Title";
import Button from "components/atoms/Button";
import { ReactComponent as BackIcon } from "assets/images/back.svg";
import { useHistory } from "react-router-dom";
import {
  useAssignStudentsToFolderMutation,
  useInviteStudentsMutation,
} from "generated/graphql";
import { useToast } from "hooks/Toast";
import { currentSchoolIdVar } from "store/school";
import { StudentCSVKeys } from "constants/students/studentCSV";
import { useTranslation } from "react-i18next";
import CSVUpload from "./components/CSVUpload";
import ManualInvite from "./components/ManualInvite";
import { ButtonWrapper, Panel, PanelWrapper, Wrapper } from "./styles";
import { ParsedStudentsCSV } from "./types";

const AddStudents = () => {
  const [CSVdata, setCSVData] = useState<ParsedStudentsCSV[]>([]);
  const [schedule, setSchedule] = useState<string>("immediately");
  const [folder, setFolder] = useState();
  const [inviteStudents] = useInviteStudentsMutation();
  const { t } = useTranslation();
  const [assignStudentMutation] = useAssignStudentsToFolderMutation();

  const Toast = useToast();

  const currentSchoolId = currentSchoolIdVar();
  const history = useHistory();

  const uploadStudents = () => {
    const studentToInvite = CSVdata.map((el) => {
      return {
        firstName: el[StudentCSVKeys.firstName],
        lastName: el[StudentCSVKeys.lastName],
        email: el[StudentCSVKeys.email],
      };
    });
    inviteStudents({
      variables: {
        data: studentToInvite,
        schoolId: Number(currentSchoolId),
        isScheduled: schedule !== "immediately",
        invitationDate: schedule === "immediately" ? 0 : Number(schedule),
      },
    })
      .then((res) => {
        if (
          res.data?.inviteStudents?.existingStudents &&
          res.data?.inviteStudents?.existingStudents.length > 0
        ) {
          const existingStudentsCount =
            res.data?.inviteStudents?.existingStudents.length;
          Toast(
            "warning",
            `${res.data?.inviteStudents?.existingStudents.length} ${
              existingStudentsCount > 1 || existingStudentsCount === 0
                ? t("global.students")
                : t("teacher.student")
            } ${t("warningAction.emailExists")}`
          );
        }
        if (
          res.data?.inviteStudents?.studentsForNewUsers &&
          res.data?.inviteStudents?.studentsForNewUsers.length > 0
        ) {
          const newStudentsCount =
            res.data?.inviteStudents?.studentsForNewUsers.length;

          if (folder) {
            assignStudentMutation({
              variables: {
                folderId: Number(folder),
                schoolId: Number(currentSchoolId),
                studentIds: res.data?.inviteStudents?.studentsForNewUsers.map(
                  (student) => Number(student?.id)
                ),
              },
            }).then(() => history.push("/students"));
          }

          Toast(
            "success",
            `${res.data?.inviteStudents?.studentsForNewUsers.length} ${
              newStudentsCount > 1 || newStudentsCount === 0
                ? t("global.students")
                : t("teacher.student")
            } ${t("successAction.wasAdded")}`
          );
          // onClose();
        }
      })
      .catch((e) => {
        Toast("error", e.message || t("errorAction.generalError"));
      });
  };

  return (
    <Wrapper>
      <Title style={{ display: "flex", gap: "0.8rem" }}>
        <BackIcon
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/students")}
        />
        Add students manually or upload many at once (.CSV)
      </Title>
      <PanelWrapper>
        <Panel>
          <ManualInvite
            isCSV={CSVdata.length > 0}
            setSchedule={setSchedule}
            setFolder={setFolder}
          />
        </Panel>
        <Panel>
          <CSVUpload CSVdata={CSVdata} setCSVData={setCSVData} />
        </Panel>
      </PanelWrapper>
      {CSVdata.length > 0 && (
        <ButtonWrapper>
          <Button
            variant="primary"
            style={{ width: "405px" }}
            onClick={uploadStudents}
          >
            Save and upload the students ({CSVdata.length})
          </Button>
          <Button
            variant="tertiary"
            onClick={() => setCSVData([])}
            style={{ width: "178px", marginLeft: "1.6rem" }}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default AddStudents;
