import React, { FC, useState, useEffect, useMemo } from "react";

import { CircleClock } from "./styles";
import { Props } from "./types";

const Clock: FC<Props> = ({ secondsLeft, onFinish, isClockRunning }) => {
  const [second, setSecond] = useState(
    Math.round(secondsLeft % 60)
      .toString()
      .padStart(2, "0")
  );
  const [minute, setMinute] = useState(
    Math.floor(secondsLeft / 60)
      .toString()
      .padStart(2, "0")
  );
  const [testCounter, setTestCounter] = useState(secondsLeft);

  // useMemo to make sure to not change initial value on every rerender, change only when secondsLeft will change
  const expireTime = useMemo(() => new Date().getTime() + secondsLeft * 1000, [
    secondsLeft,
  ]);

  useEffect(() => {
    const intervalId =
      testCounter >= 0 && isClockRunning && expireTime
        ? setInterval(() => {
            const secondCounter = testCounter % 60;
            const minuteCounter = Math.floor(testCounter / 60);

            const computedSecond =
              String(secondCounter).length === 1
                ? `0${secondCounter}`
                : secondCounter;
            const computedMinute =
              String(minuteCounter).length === 1
                ? `0${minuteCounter}`
                : minuteCounter;

            setSecond(computedSecond.toString());
            setMinute(computedMinute.toString());

            const newCounter = Math.round(
              (expireTime - new Date().getTime()) / 1000
            );

            setTestCounter(newCounter);

            if (secondCounter === 0 && minuteCounter === 0) {
              onFinish();
            }
          }, 200)
        : 0;

    return () => clearInterval(intervalId);
  }, [testCounter, onFinish, isClockRunning, expireTime]);

  return (
    <CircleClock>
      {minute}:{second}
    </CircleClock>
  );
};
export default Clock;
