import styled from "styled-components";

import { ReactComponent as TutorialImage } from "assets/images/tutorial-image.svg";
import { ReactComponent as PlayImage } from "assets/images/play.svg";
import CloseImage from "assets/images/close.svg";

import media from "../../../helpers/media";

export const TutorialWrapper = styled.div`
  display: flex;
  width: calc(100% - 1.8rem * 2);

  ${media.MD`
    width: 100%;
    margin: 1.8rem;
  `}

  ${media.SM`
  margin: 1.8rem 0;
`}
`;

export const TutorialCard = styled.div`
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  min-height: 188px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

export const IconContainer = styled.div`
  margin: 1rem;
  ${media.MD`
    display: none;
`}
  ${media.SM`
  display: block;
  `}
  ${media.XXS`
  display: none;
  `}
`;

export const TutorialIcon = styled(TutorialImage)`
  width: 97px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: flex-start;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 460px;
  width: 100%;
  min-width: 250px;
  padding: 0 1rem 1rem 1rem;
`;

export const TutorialHeader = styled.h2`
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  font-family: "Roboto Slab";
  margin-top: 2rem;
  margin-bottom: 1.6rem;
`;

export const TutorialDescription = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "Roboto";
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: 500;
  font-family: "Roboto";
  margin: 1rem;
`;

export const PlayIcon = styled(PlayImage)`
  fill: ${({ theme }) => theme.colors.white};
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
`;

export const PlayButton = styled.button`
  padding: 0.5rem 2.5rem;
  background: ${({ theme }) => theme.colors.denim};
  border-radius: 2.5rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.02rem;
  height: 4.8rem;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.matisse};
  }
`;

export const CloseIcon = styled.div`
  position: relative;
  top: 1rem;
  right: 1rem;
  background-image: url(${CloseImage});
  width: 11px;
  height: 11px;
  padding: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
`;
