import React, { FC, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  useGetSchoolQuery,
  useCreatePaymentSessionMutation,
  useSendInvoiceRequestMutation,
} from "generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { authenticatedUserVar } from "store/authenticatedUser";
import { useToast } from "hooks/Toast";
import Modal from "components/atoms/Modal";
import NumberInput from "components/molecules/NumberInput";
import { ReactComponent as CreditCard } from "assets/images/credit-card.svg";
import { ReactComponent as Invoice } from "assets/images/invoice.svg";
import { currentSchoolIdVar } from "store/school";
import { ReactComponent as CheckedCircle } from "assets/images/circle-checked.svg";

import {
  Wrapper,
  Label,
  ContentWrapper,
  StyledCard,
  IconWrapper,
  CardButton,
  CreditsInfo,
  StyledButton,
  ButtonsSection,
  StyledLinkIcon,
  ThankYou,
  StyledOk,
  WrapperNarrow,
} from "./styles";
import { Props, PaymentOptions } from "./types";

const AddCreditsModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const Toast = useToast();

  const [selectedMethod, setSelectedMethod] = useState<PaymentOptions>("card");
  const [currentScreen, setCurrentScreen] = useState<
    "default" | "confirmation"
  >("default");
  const [credits, setCredits] = useState<string>("");
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const [createPaymentSession] = useCreatePaymentSessionMutation();
  const [sendInvoiceRequest] = useSendInvoiceRequestMutation();
  const authenticatedUser = useReactiveVar(authenticatedUserVar);

  const { data: currentSchool } = useGetSchoolQuery({
    variables: {
      id: currentSchoolId,
    },
  });

  const handleSetCredits = (creditsVal: string) => {
    setCredits(creditsVal);
  };

  const validateCredits = () => {
    if (credits.length === 0 || Number(credits) <= 0) {
      Toast("error", t("errorAction.numberOfCredits"));
      return false;
    }
    return true;
  };

  const handlePay = () => {
    const isFormValid = validateCredits();
    if (!isFormValid) return;

    if (currentSchool && currentSchool.school && currentSchool.school.credit) {
      const creditId = currentSchool.school.credit.id;

      createPaymentSession({
        variables: {
          creditId: Number(creditId),
          quantity: Number(credits),
        },
      })
        .then((sessionIdData) => {
          if (stripe && sessionIdData.data) {
            const sessionId = sessionIdData.data.createPaymentSession;

            if (sessionId) {
              stripe.redirectToCheckout({
                sessionId,
              });
            }
          }
        })
        .catch((error) => {
          Toast("error", error.message || t("errorAction.generalError"));
        });
    }
  };

  const handleRequestInvoice = () => {
    const isFormValid = validateCredits();
    if (!isFormValid) return;
    if (authenticatedUser.authenticatedItem) {
      sendInvoiceRequest({
        variables: {
          schoolId: Number(currentSchoolId),
          userId: Number(authenticatedUser.authenticatedItem.id),
          quantity: Number(credits),
        },
      })
        .then(() => {
          //   Toast("success", "smisiownik");
          setCurrentScreen("confirmation");
          // onClose();
        })
        .catch((error) => {
          Toast("error", error.message || t("errorAction.generalError"));
        });
    }
  };

  const amountToPay = (Number(credits) * 20).toString();
  const paymentContent = (
    <>
      {t("teacher.pay")} ({amountToPay} {t("global.currency")}){" "}
      <StyledLinkIcon />
    </>
  );

  const buttonText =
    selectedMethod === "card" ? paymentContent : t("teacher.requestInvoice");

  const submitAction =
    selectedMethod === "card" ? handlePay : handleRequestInvoice;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        currentScreen === "default"
          ? t("teacher.addCredits")
          : t("teacher.thankYouForOrder")
      }
      size="narrow"
      rightHeaderContent={
        currentScreen === "confirmation" ? (
          <div>
            <CheckedCircle style={{ width: "4rem", height: "4rem" }} />
          </div>
        ) : undefined
      }
      closeIcon={currentScreen === "default"}
    >
      {" "}
      {currentScreen === "default" ? (
        <>
          <Wrapper>
            <Label>{t("teacher.method")}</Label>
            <ContentWrapper>
              <CardButton onClick={() => setSelectedMethod("card")}>
                <StyledCard active={selectedMethod === "card"}>
                  <IconWrapper>
                    <CreditCard />
                  </IconWrapper>
                  {t("global.creditCard")}
                </StyledCard>
              </CardButton>
              <CardButton onClick={() => setSelectedMethod("invoice")}>
                <StyledCard active={selectedMethod === "invoice"}>
                  <IconWrapper>
                    <Invoice />
                  </IconWrapper>
                  {t("teacher.requestInvoice")}
                </StyledCard>
              </CardButton>
            </ContentWrapper>
          </Wrapper>
          <Wrapper>
            <Label>{t("teacher.numberOfCredits")}</Label>
            <ContentWrapper>
              <NumberInput
                name="creditsNum"
                value={credits}
                handleChange={handleSetCredits}
              />
              <CreditsInfo>
                1 {t("global.credit")} = 20 {t("global.currency")}
              </CreditsInfo>
            </ContentWrapper>
          </Wrapper>
          <ButtonsSection>
            <StyledButton variant="primary" onClick={submitAction}>
              {buttonText}
            </StyledButton>
            <StyledButton variant="link" onClick={onClose}>
              {t("actions.cancel")}
            </StyledButton>
          </ButtonsSection>
        </>
      ) : (
        <WrapperNarrow>
          <ContentWrapper>
            <ThankYou>{t("teacher.invoiceInOneDay")}</ThankYou>
          </ContentWrapper>
          <ButtonsSection>
            <StyledOk variant="primary" onClick={onClose}>
              {t("actions.ok")}
            </StyledOk>
          </ButtonsSection>
        </WrapperNarrow>
      )}
    </Modal>
  );
};

export default AddCreditsModal;
