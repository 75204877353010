import React, { FC, Fragment } from "react";

import { ReactComponent as SmallLogo } from "assets/images/smallLogo.svg";

import { Props } from "./types";
import { Container, StyledLink, Dot } from "./styles";

const Footer: FC<Props> = ({ footerItems }) => {
  return (
    <Container>
      <SmallLogo />
      {footerItems.map((item, index) => {
        return (
          <Fragment key={item.title}>
            <StyledLink href={item.to}>{item.title}</StyledLink>
            {index !== footerItems.length - 1 && <Dot />}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default Footer;
