import styled, { css, DefaultTheme } from "styled-components";

import Ellipse from "assets/images/ellipse.svg";
import Button from "components/atoms/Button";
import media from "helpers/media";

import { AssessmentType } from "types";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteLilac};
  margin: auto;
  padding-top: 11.6rem;

  ${media.SM`
    padding-top: 8.6rem;
    width: 100%;
  `};
`;

export const FixedWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 9.6rem;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);

  ${media.SM`
    width: 100%;
    height: 7.4rem;
  `};
`;

export const HeaderWrapper = styled.div`
  max-width: 114rem;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  line-height: 2.8rem;
  font-size: 2rem;
  height: 9.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  ${media.SM`
    width: 100%;
    height: 7.4rem;
  `};
`;

export const Header = styled.div`
  max-width: 80rem;
  padding-right: 1.6rem;
  border-left: 0.4rem solid ${({ theme }) => theme.colors.defaultColor};
  padding-left: 1.6rem;
  flex-grow: 1;

  ${media.SM`
    border-left: none;
    font-weight: 500;
    font-size: 1.4rem;
  `};
`;

export const Border = styled.div`
  ${media.SM`
    height: 70%;
    width: 0.5rem;
    background-color: ${({ theme }: { theme: DefaultTheme }) =>
      theme.colors.defaultColor};
    margin-left: 1.4rem;
    border-radius: 5px;
  `};
`;

export const MainWrapper = styled.div`
  display: flex;
  position: relative;
  width: 114rem;
  margin: auto;

  ${media.SM`
    width:96%;
    margin-left: 2%;
  `};
`;

export const QuestionsWrapper = styled.div`
  width: 73rem;
`;

export const Question = styled.div`
  background: #ffffff;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 3rem;
  padding: 2.4rem;
  padding-left: 9.2rem;
  position: relative;
  margin-bottom: 2rem;
`;

export const QuestionNumber = styled.div`
  position: absolute;
  top: 2.4rem;
  left: 3.2rem;
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;

  letter-spacing: 0.05rem;

  color: ${({ theme }) => theme.colors.defaultColor};

  ${media.SM`
    left: 2rem;
    font-size: 1.6rem;
  `};
`;

export const QuestionContent = styled.div`
  margin-bottom: 2.4rem;
  max-width: 55rem;

  p {
    margin: 0;
    padding: 0;
  }

  strong {
    color: ${({ theme }) => theme.colors.defaultColor};
  }

  ${media.SM`
    padding-top: 3.8rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  `};
`;

export const AnswersContent = styled.div<{ type?: AssessmentType }>`
  display: flex;
  flex-direction: column;
  ${({ type }) =>
    type === "abstract" &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
    `}

  ${media.SM`
      width: 100%;
  `};
`;

export const Answer = styled.div<{
  selected: boolean;
  notInteractive?: boolean;
  wrong?: boolean;
}>`
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid transparent;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.2rem;
  text-align: center;
  display: inline-block;
  min-width: 20rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 2.3rem;
  background-image: url(${Ellipse});
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 1.6rem;
  white-space: nowrap;
  padding-left: 4rem;
  padding-right: 1rem;
  box-shadow: inset 0 0 0 0.1rem ${({ theme }) => theme.colors.geyser};

  p {
    margin: 0;
    padding: 0;
  }

  ${({ notInteractive, theme }) =>
    !notInteractive &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: inset 0 0 0 0.2rem ${theme.colors.defaultColor};
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.25" fill="white" stroke="${({ theme }) =>
        theme.colors.defaultColor.replace("#", "%23")}" stroke-width="1.5"/>
      <circle cx="10" cy="10" r="4" fill="${({ theme }) =>
        theme.colors.defaultColor.replace("#", "%23")}"/>
      </svg>');
      box-shadow: inset 0 0 0 0.2rem ${({ theme }) => theme.colors.defaultColor};
    `}
    ${({ wrong }) =>
    wrong &&
    css`
      background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.25" fill="white" stroke="${({ theme }) =>
        theme.colors.defaultColor.replace("#", "%23")}" stroke-width="1.5"/>
    <circle cx="10" cy="10" r="4" fill="${({ theme }) =>
        theme.colors.defaultColor.replace("#", "%23")}"/>
    </svg>');
      box-shadow: inset 0 0 0 0.2rem ${({ theme }) => theme.colors.defaultColor};
      filter: grayscale(100);
      opacity: 0.5;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 1.2rem;
        width: 85%;
        border-bottom: 0.2rem solid ${({ theme }) => theme.colors.defaultColor};
      }
    `}

    ${media.SM`
      width: 100%;
      font-size: 1.6rem;
    `};
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  margin-left: 75rem;
  top: 15.5rem;

  ${media.SM`
    display: none;
  `};
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  width: min-content;
  margin-bottom: 1.6rem;
  margin-left: 8rem;

  ${media.SM`
    width: 70%;
    margin: 0 0 1.6rem 0;
  `};
`;

export const AnswersWrapper = styled.div<{ pending?: boolean }>`
  display: flex;
  flex: 1;
  align-items: flex-start;

  ${({ pending }) =>
    pending &&
    css`
      opacity: 0.8;
      cursor: progress;
    `}

  ${media.SM`
    flex-direction: column;
    width: 100%;
    font-size: 1.2rem;
  `};
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const SubquestionContent = styled.div`
  width: 100%;
`;

export const SubquestionContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 6.4rem;
`;

export const Subquestion = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.defaultColor};
  margin-bottom: 2.4rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderProgressBar = styled.div`
  display: none;

  ${media.SM`
    display: flex;
  `};
`;

export const ProgressBarText = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.shuttleGray};
  text-transform: lowercase;
`;

export const ProgressBarPercentage = styled.div`
  color: ${({ theme }) => theme.colors.defaultColor};
  font-weight: 500;
  margin-left: 0.5rem;
`;

export const DangerousInner = styled.div``;

export const Title = styled.h3`
  font-family: "Roboto Slab";
`;
