import React, { FC, useState, useEffect, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { ReactComponent as Pencil } from "assets/images/pencil.svg";
import Card from "components/atoms/Card";
import PreviousYearsData from "components/molecules/PreviousYearsData";
import TeachersList from "components/molecules/TeachersList";
import useClickOutside from "hooks/clickOutSide";
import { allSchoolsVar, currentSchoolIdVar } from "store/school";
import {
  useGetSchoolQuery,
  useDeleteTeacherMutation,
  useRemoveSchoolAdminMutation,
  useGetAllTeachersQuery,
  useGetAuthenticatedUserQuery,
  useGetSponsorsQuery,
} from "generated/graphql";
import { useToast } from "hooks/Toast";
import Loader from "components/atoms/Loader";
import SchoolDropdown from "components/molecules/SchoolDropdown";

import { useHistory, useLocation } from "react-router-dom";
import paths from "routes/paths";
import { TeacherInfo } from "components/molecules/TeachersList/types";
import {
  Container,
  TableSection,
  SchoolDetails,
  Wrapper,
  Label,
  Value,
  Inforow,
  SchoolInfoWrapper,
  SchoolIcon,
  SchoolDataWrapper,
  PencilButton,
} from "./styles";
import AddTeacherModal from "./components/AddTeacherModal";
import EditSchoolModal from "./components/EditSchoolModal";
import TeacherDetailsModal from "./components/TeacherDetailsModal";

const School: FC = () => {
  const { t } = useTranslation();

  const [isUserSchoolAdmin, setIsUserSchoolAdmin] = useState(false);
  const [isEditSchoolModalOpen, setEditSchoolModalOpen] = useState(false);
  const allSchools = useReactiveVar(allSchoolsVar);
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const [addTeacherModalOpen, setAddTeacherModalOpen] = useState(false);
  const [isTeacherDetailsModalOpen, setIsTeacherDetailsModalOpen] = useState(
    false
  );
  const [isSponsorActive, setIsSponsorActive] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherInfo | null>(
    null
  );

  const [deleteTeacher] = useDeleteTeacherMutation();
  const [removeSchoolAdmin] = useRemoveSchoolAdminMutation();

  const Toast = useToast();

  const location = useLocation();
  const history = useHistory();

  const { data: authenticatedUser } = useGetAuthenticatedUserQuery();

  const isAdmin = authenticatedUser?.authenticatedItem?.isAdmin || false;

  if (
    location.pathname === paths.paymentSuccess &&
    authenticatedUser?.authenticatedItem?.teachers &&
    authenticatedUser?.authenticatedItem?.teachers[0].school?.isIndividual
  ) {
    history.replace(paths.selfRegistrationPaymentSuccess);
  }
  if (
    location.pathname === paths.paymentFailed &&
    authenticatedUser?.authenticatedItem?.teachers &&
    authenticatedUser?.authenticatedItem?.teachers[0].school?.isIndividual
  ) {
    history.replace(paths.selfRegistrationPaymentFailed);
  }

  const { data: currentSchool, loading, refetch } = useGetSchoolQuery({
    variables: {
      id: currentSchoolId,
    },
  });

  const now = useMemo(() => new Date(), []);

  const { data: sponsorsData } = useGetSponsorsQuery({
    variables: {
      date: now,
      countryId: currentSchool?.school?.country?.id || "-1",
    },
  });

  useEffect(() => {
    if (sponsorsData && sponsorsData.sponsors?.length) {
      setIsSponsorActive(true);
    }
  }, [sponsorsData]);

  const {
    data: teachersData,
    loading: teachersLoading,
    refetch: teachersRefetch,
  } = useGetAllTeachersQuery({ variables: { id: currentSchoolId } });

  useEffect(() => {
    if (currentSchool) {
      const isSchoolAdmin = currentSchool?.school?.schoolAdmins?.some(
        (admin) => {
          return admin.id === currentSchool.authenticatedItem?.id;
        }
      );
      setIsUserSchoolAdmin(Boolean(isSchoolAdmin));
    }
  }, [currentSchool]);

  const openTeacherModal = () => {
    setAddTeacherModalOpen(true);
  };

  const closeTeacherModal = () => {
    refetch();
    teachersRefetch();
    setAddTeacherModalOpen(false);
  };

  const openTeacherDetailsModal = (teacher: TeacherInfo) => {
    setSelectedTeacher(teacher);
    setIsTeacherDetailsModalOpen(true);
  };

  const refreshSelectedTeacher = () => {
    teachersRefetch();
    refetch();
  };

  const closeTeacherDetailsModal = () => {
    refetch();
    teachersRefetch();
    setIsTeacherDetailsModalOpen(false);
  };

  const {
    visible: dropdownVisible,
    setVisible: setDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const toggleDropdownVisible = () => setDropdownVisible(!dropdownVisible);

  const setCurrentSchoolId = (id: string) => {
    currentSchoolIdVar(id);
    localStorage.setItem("CF_currentSchool", id);
  };

  const handleDeleteTeacher = (id: string, schoolId: string | null) => {
    if (schoolId) {
      removeSchoolAdmin({
        variables: { userId: Number(id), schoolId: Number(schoolId) },
      })
        .then(() => {
          Toast("success", t("successAction.schoolAdminRemoved"));
        })
        .catch((error) => {
          Toast("error", error.message || t("errorAction.generalError"));
        })
        .finally(() => {
          refetch();
          teachersRefetch();
        });
    } else {
      deleteTeacher({
        variables: { id },
      })
        .then(() => {
          Toast("success", t("successAction.teacherRemoved"));
        })
        .catch((error) => {
          Toast("error", error.message || t("errorAction.generalError"));
        })
        .finally(() => {
          refetch();
          teachersRefetch();
        });
    }
  };

  const openEditSchoolModal = () => {
    setEditSchoolModalOpen(true);
  };
  const closeEditSchoolModal = () => {
    setEditSchoolModalOpen(false);
  };

  const onSuccessSchoolEdit = () => {
    Toast("success", t("successAction.schoolEdited"));
    refetch();
    closeEditSchoolModal();
  };

  if (loading || teachersLoading) return <Loader />;

  if (!currentSchoolId) {
    setCurrentSchoolId(
      allSchools && allSchools.schools
        ? allSchools?.schools[0]?.id || "-1"
        : "-1"
    );
  }

  return (
    <Wrapper>
      <Container>
        <AddTeacherModal
          isOpen={addTeacherModalOpen}
          title={t("teacher.addTeacher")}
          onClose={closeTeacherModal}
        />
        <EditSchoolModal
          isOpen={isEditSchoolModalOpen}
          currentSchool={currentSchool}
          onClose={closeEditSchoolModal}
          onSuccessSchoolEdit={onSuccessSchoolEdit}
          sponsorActive={isSponsorActive}
        />
        <TeacherDetailsModal
          isOpen={isTeacherDetailsModalOpen}
          currentSchool={currentSchool}
          onClose={closeTeacherDetailsModal}
          teacher={selectedTeacher}
          isAdmin={isAdmin}
          handleDeleteUser={handleDeleteTeacher}
          refreshSelectedTeacher={refreshSelectedTeacher}
        />
        <SchoolDataWrapper>
          <TableSection>
            <Card
              styles={{
                marginRight: "1.4rem",
                padding: "2.4rem 3.2rem",
                width: "100%",
                height: "100%",
              }}
            >
              <TeachersList
                handleAddTeacher={openTeacherModal}
                currentSchool={currentSchool}
                teachersData={teachersData}
                openDetailsModal={openTeacherDetailsModal}
              />
            </Card>
          </TableSection>
          <SchoolDetails>
            <Card
              styles={{
                padding: "2rem 2.4rem",
                marginBottom: "1rem",
                height: "100%",
              }}
            >
              <SchoolInfoWrapper
                onClick={toggleDropdownVisible}
                ref={containerRef}
              >
                <SchoolIcon />
                <SchoolDropdown
                  visible={dropdownVisible}
                  onSelect={setCurrentSchoolId}
                  context="teacher"
                  searchingFor="teachers"
                />
                {isUserSchoolAdmin && (
                  <PencilButton
                    type="button"
                    variant="link"
                    onClick={openEditSchoolModal}
                  >
                    {t("actions.edit")}{" "}
                    <Pencil style={{ marginLeft: "1rem" }} />
                  </PencilButton>
                )}
              </SchoolInfoWrapper>

              <Inforow>
                <Label>{t("global.principal")}</Label>
                <Value>{currentSchool?.school?.principalName}</Value>
              </Inforow>
              <Inforow>
                <Label>{t("global.email")}</Label>
                <Value>{currentSchool?.school?.email}</Value>
              </Inforow>

              <Inforow>
                <Label>{t("global.phoneNumber")}</Label>
                <Value>{currentSchool?.school?.phone}</Value>
              </Inforow>

              <Inforow align="flex-start">
                <Label>{t("global.address")}</Label>
                <Value>
                  {currentSchool?.school?.address}
                  <br />
                  {currentSchool?.school?.city} {currentSchool?.school?.state}{" "}
                  {currentSchool?.school?.zip} <br />
                  {currentSchool?.school?.country?.name || ""}
                </Value>
              </Inforow>

              <Inforow>
                <Label>{t("global.code")}</Label>
                <Value>{currentSchool?.school?.code}</Value>
              </Inforow>
            </Card>
          </SchoolDetails>
        </SchoolDataWrapper>
      </Container>
      <Container>
        <Card>
          <PreviousYearsData />
        </Card>
      </Container>
    </Wrapper>
  );
};

export default School;
