import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Clock from "components/atoms/Clock";
import Loader from "components/atoms/Loader";

import { answeredQuestionsCountVar, questionsCountVar } from "store/test";
import { useToast } from "hooks/Toast";
import { AssessmentType } from "types";
import {
  useCompleteAptitudeTestMutation,
  useCompleteInterestInventoryTestMutation,
  useGetAptitudeTestAttemptQuery,
} from "generated/graphql";

import paths from "routes/paths";
import { getTestName } from "helpers/getTestName";

import {
  Wrapper,
  HeaderWrapper,
  Header,
  FixedWrapper,
  MainWrapper,
  Border,
  HeaderProgressBar,
  ProgressBarText,
  ProgressBarPercentage,
  Title,
} from "./styles";

import FinishedTestModal from "../Test/Modals/FinishedTestModals/FinishedTestModal";
import TimesUpModal from "../Test/Modals/FinishedTestModals/TimesUpModal";
import Aptitude from "./Aptitude";
import Interest from "./Interest";
import ConfirmEndOfTestModal from "../Test/Modals/FinishedTestModals/ConfirmEndOfTestModal";

const Tests = () => {
  const { t } = useTranslation();
  const { type, id } = useParams<{ type: AssessmentType; id: string }>();
  const Toast = useToast();
  const history = useHistory();

  const [finishAptitudeTest] = useCompleteAptitudeTestMutation();
  const [
    finishInterestInventoryTest,
  ] = useCompleteInterestInventoryTestMutation();

  const [testFinishedModal, setTestFinishedModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const questionsCount = useReactiveVar(questionsCountVar);
  const answeredQuestionsCount = useReactiveVar(answeredQuestionsCountVar);
  const [expired, setExpired] = useState(false);
  const [isClockRunning, setIsClockRunning] = useState(true);
  const [
    isInterestAnswersHighlighted,
    setIsInterestAnswersHighlighted,
  ] = useState(false);
  const [isTooFewInterestAnswers, setIsTooFewInterestAnswers] = useState(false);

  const {
    data: testData,
    loading: testLoading,
  } = useGetAptitudeTestAttemptQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: type === "interest",
  });

  const handleUncheckedQuestions = () => {
    if (isTooFewInterestAnswers) {
      setIsInterestAnswersHighlighted(true);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
    handleUncheckedQuestions();
    const firstWithoutAnswer = document.querySelector(".unanswered");
    firstWithoutAnswer?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const finishTest = () => {
    setTestFinishedModal(true);
    setIsClockRunning(false);
    if (type === "interest") {
      finishInterestInventoryTest({
        variables: { id: Number(id) },
      }).catch((err) => Toast("error", err.message));
    } else {
      finishAptitudeTest({ variables: { id: Number(id) } }).catch((err) =>
        Toast("error", err.message)
      );
    }
  };
  const onTimesUp = () => {
    setExpired(true);
    setIsClockRunning(false);
  };

  const finishAssessment = () => {
    history.push(paths.studentDashboard);
  };

  if (testLoading) return <Loader />;

  let secondsLeft = 0;

  if (type !== "interest") {
    secondsLeft =
      testData?.aptitudeTestAttempt?.leftTime &&
      !Number.isNaN(testData?.aptitudeTestAttempt?.leftTime)
        ? Number(testData?.aptitudeTestAttempt?.leftTime)
        : 0;

    if (secondsLeft <= 0 || testData?.aptitudeTestAttempt?.isCompleted)
      history.push(paths.studentDashboard);
  }

  const completedPercent = Math.round(
    (answeredQuestionsCount * 100) / questionsCount
  );

  return (
    <Wrapper>
      {/* MODALS */}
      <ConfirmEndOfTestModal
        isOpen={confirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={finishTest}
        isInterestInventoryBlocked={isTooFewInterestAnswers}
      />
      <FinishedTestModal
        isOpen={testFinishedModal}
        onClose={finishAssessment}
        testType={getTestName(type)}
      />
      {expired && <TimesUpModal isOpen={expired} onClose={() => {}} />}

      <FixedWrapper>
        <HeaderWrapper>
          <Border />
          <Header>
            <Title>{getTestName(type)}</Title>
            <HeaderProgressBar>
              <ProgressBarText>
                {answeredQuestionsCount}/{questionsCount}{" "}
                {t("student.completed").toLowerCase()}
              </ProgressBarText>
              <ProgressBarPercentage>
                {" "}
                ({completedPercent}%)
              </ProgressBarPercentage>
            </HeaderProgressBar>
          </Header>
          {type !== "interest" && (
            <Clock
              secondsLeft={secondsLeft}
              onFinish={onTimesUp}
              isClockRunning={isClockRunning}
            />
          )}
        </HeaderWrapper>
      </FixedWrapper>
      <MainWrapper>
        {type === "interest" ? (
          <Interest
            id={id}
            finishTest={showConfirmationModal}
            isUncheckedHighlighted={isInterestAnswersHighlighted}
            setIsTooFewInterestAnswers={setIsTooFewInterestAnswers}
          />
        ) : (
          <Aptitude type={type} id={id} finishTest={showConfirmationModal} />
        )}
      </MainWrapper>
    </Wrapper>
  );
};

export default Tests;
