import { AssessmentType } from "types";

export const getTestDuration = (type: AssessmentType) => {
  let duration = 0;

  switch (type) {
    case "abstract":
      duration = 30;
      break;

    case "interest":
      duration = 0;
      break;

    case "numerical":
      duration = 30;
      break;

    case "verbal1":
      duration = 5;
      break;

    case "verbal2":
      duration = 10;
      break;

    case "verbal3":
      duration = 20;
      break;

    default:
      duration = 0;
  }

  return duration;
};
