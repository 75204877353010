import styled, { css } from "styled-components";

import { ReactComponent as ArrowRightIcon } from "assets/images/arrow-right.svg";
import { ReactComponent as CalculatorIcon } from "assets/images/calculator-icon.svg";
import { ReactComponent as DictionaryIcon } from "assets/images/dictionary-icon.svg";

import Colors from "constants/colors";
import { AssessmentStatus, AssessmentType } from "types";

import { ZIndex } from "constants/theme";
import media from "helpers/media";
import { AssessmentColor } from "./types";

const assessmentColor: AssessmentColor = {
  abstract: {
    background: Colors.sail,
    border: Colors.pictonBlue,
  },
  interest: {
    background: Colors.iceCold,
    border: Colors.pictonBlueLight,
  },
  numerical: {
    background: Colors.kobi,
    border: Colors.mulberry,
  },
  verbal1: {
    background: Colors.astra,
    border: Colors.saffron,
  },
  verbal2: {
    background: Colors.astra,
    border: Colors.saffron,
  },
  verbal3: {
    background: Colors.astra,
    border: Colors.saffron,
  },
};

export const CardWrapper = styled.div<{ flexOrder?: number }>`
  cursor: default;
  display: flex;
  margin: 1.8rem;
  position: relative;
  min-height: 18rem;
  z-index: ${ZIndex.Default};
  width: calc(50% - 1.8rem * 2);

  &:nth-child(even) {
    margin-right: 0;
  }

  ${media.MD`
    width: 100%;
    margin: 1.8rem;
    min-height: 15rem;
  `}

  ${media.SM`
    margin: 1.8rem 0;
  `}

  ${({ flexOrder }) =>
    flexOrder &&
    css`
      ${media.MD`
        order: ${flexOrder};
      `}
    `}
`;

export const Card = styled.div<{
  status: AssessmentStatus;
  type: AssessmentType;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.4rem;
  position: relative;
  width: 100%;

  ${(props) => {
    let styles = "";

    if (props.status === "not-available") {
      styles = "opacity: 0.5;";
    }

    if (props.status === "available") {
      styles = `
        border-color: ${assessmentColor[props.type].border};

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
        }

        &::after {
          background-color: ${assessmentColor[props.type].background};
          border: 2px solid ${assessmentColor[props.type].border};
          border-radius: 8px;
          content: "";
          height: 100%;
          position: absolute;
          left: 10px;
          top: 10px;
          width: 100%;
          z-index: -${ZIndex.Default};
        }
      `;
    }

    return styles;
  }}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.shuttleGray};
  font-weight: 500;
  font-size: 2rem;
  font-family: "Roboto Slab";
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: none;
  white-space: pre-line;
  flex: 1;
`;

export const VerticalLine = styled.div<{
  type: AssessmentType;
}>`
  min-height: 2.6rem;
  margin-right: 3.2rem;
  background: ${({ type }) => assessmentColor[type].border};
  width: 4px;
`;

export const LeftBottom = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  margin: 0.8rem 0;
`;

export const ArrowRight = styled(ArrowRightIcon)<{ type: AssessmentType }>`
  stroke: ${({ type }) => assessmentColor[type].border};
`;

export const CalculatorStyledIcon = styled(CalculatorIcon)<{
  type: AssessmentType;
}>`
  display: ${({ type }) => (type === "numerical" ? "block" : "none")};
  position: relative;
  bottom: 1rem;
  left: 1rem;
`;

export const DictionaryStyledIcon = styled(DictionaryIcon)<{
  type: AssessmentType;
}>`
  display: ${({ type }) => (type === "verbal1" ? "block" : "none")};
  position: relative;
  bottom: 1rem;
  left: 1rem;
`;

export const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 3.6rem;
`;

export const Button = styled.button`
  border: none;
  background-color: none;
  padding: 0;
  margin-left: 0.8rem;
`;

export const Italic = styled.span`
  font-style: italic;
  font-size: 1.4rem;
  margin-left: 0.6rem;
`;

export const Completed = styled.span`
  color: ${({ theme }) => theme.colors.oceanGreen};
`;

export const MobileOnly = styled.div`
  display: none;
  ${media.SM`
    display: block;
  `}
`;
