import styled from "styled-components";

import media from "helpers/media";

export const StyledMain = styled.main`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.whiteLilac};
  padding: 4.8rem 0;

  ${media.SM`
    padding: 2.8rem 0;
  `}
`;

export const StyledHeader = styled.header`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  position: relative;
`;

export const StyledFooter = styled.footer`
  height: 5.4rem;
`;
