import i18n from "utils/i18n/i18n";

import { AssessmentType } from "types";

export const getTestName = (type: AssessmentType) => {
  let testName = "";

  switch (type) {
    case "abstract":
      testName = i18n.t("test.abstract.abstractInventory");
      break;
    case "interest":
      testName = i18n.t("test.interest.interestInventory");
      break;

    case "numerical":
      testName = i18n.t("test.numerical.numericalReasoning");
      break;
    case "verbal1":
      testName = i18n.t("test.vocabulary.verbalVocabulary");
      break;
    case "verbal2":
      testName = i18n.t("test.comprehension.verbalComprehension");
      break;
    case "verbal3":
      testName = i18n.t("test.reasoning.verbalReasoning");
      break;

    default:
      testName = "";
  }
  return testName;
};
