import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import NavigationBar from "components/molecules/NavigationBar";
import Footer from "components/molecules/Footer";

import paths from "routes/paths";
import { StyledMain, StyledHeader, StyledFooter } from "./styles";

const Main: FC = ({ children }) => {
  const { t } = useTranslation();

  const teacherNav = [
    { to: paths.studentsEmptyFolder, title: t("global.students") },
    { to: paths.school, title: t("global.school") },
    { to: "https://careerfit.com/faq", title: t("global.faq") },
    { to: paths.folders, title: t("global.folders") },
  ];

  const footer = [{ to: "https://careerfit.com/", title: "CareerFit.com" }];

  return (
    <>
      <StyledHeader>
        <NavigationBar navigationItems={teacherNav} />
      </StyledHeader>
      <StyledMain>{children}</StyledMain>
      <StyledFooter>
        <Footer footerItems={footer} />
      </StyledFooter>
    </>
  );
};

export default Main;
