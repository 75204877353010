import styled from "styled-components";

import { ReactComponent as CheckIcon } from "assets/images/circle-checked-colorize.svg";
import { ReactComponent as CalculatorIcon } from "assets/images/calculator-icon.svg";
import { ReactComponent as DictionaryIcon } from "assets/images/dictionary-icon.svg";

import media from "helpers/media";
import { AssessmentType } from "../../../types";

export const Container = styled.div`
  display: flex;
  width: 100%;

  ${media.MD`
    flex-direction: column;
  `};
`;

export const CheckIconWrapper = styled.div`
  margin-right: 1.2rem;
  min-width: 2rem;
  min-height: 2rem;
`;

export const ContentText = styled.div`
  font-size: 1.8rem;
`;

export const ContentRow = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const Header = styled.h3`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.defaultColor};
`;

export const SubHeader = styled.h2`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 3.2rem;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

export const HeaderSection = styled.div`
  margin-bottom: 4.8rem;
`;

export const Content = styled.div`
  padding: 0 3rem;
  margin-bottom: 4rem;
`;

export const CardWrapper = styled.div`
  width: calc(60% - 7.4rem);

  ${media.MD`
    width: 100%;
    padding: 0 2rem;
  `};
`;

export const SideMenuWrapper = styled.div`
  width: 20%;
  margin-right: 7.4rem;

  ${media.MD`
    display: none;
  `};
`;

export const StyledIcon = styled(CheckIcon)`
  #back {
    fill: ${({ theme }) => theme.colors.secondaryColor};
  }
  #front {
    stroke: ${({ theme }) => theme.colors.defaultColor};
  }
`;

export const ContentBanner = styled.div`
  display: flex;
  background-color: #f5f7ff;
  border: 1.5px solid #bfc5dd;
  border-radius: 0.8rem;
  padding: 1.6rem;
  width: 100%;
  align-items: center;

  ${media.XXS`
  flex-direction: column;
  align-items: center;
`};
`;

export const CalculatorStyledIcon = styled(CalculatorIcon)<{
  type: AssessmentType;
}>`
  display: ${({ type }) => (type === "numerical" ? "block" : "none")};
  min-width: 6rem;
  min-height: 6rem;
`;

export const DictionaryStyledIcon = styled(DictionaryIcon)<{
  type: AssessmentType;
}>`
  display: ${({ type }) => (type === "verbal1" ? "block" : "none")};
  min-width: 6rem;
  min-height: 6rem;
`;

export const BannerText = styled.div`
  padding: 1rem;
  font-size: 1.6rem;
  margin-left: 1.4rem;

  ${media.XXS`
  margin: 0;
`};
`;
