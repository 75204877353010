import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useStartAptitudeTestMutation,
  useStartInterestInventoryTestMutation,
} from "generated/graphql";
import paths from "routes/paths";
import { AssessmentType } from "types";
import { useToast } from "hooks/Toast";
import TestInformation from "components/molecules/TestInformation";
import ImportantInfoModal from "../Modals/ImportantInfoModal";

import { StyledButton, ButtonsSection } from "./styles";
import { trainingFinishedContent } from "./data";

const TrainingFinished = () => {
  const { t } = useTranslation();
  const { type, id } = useParams<{ type: AssessmentType; id: string }>();
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const history = useHistory();
  const Toast = useToast();

  const [startAptitudeTest] = useStartAptitudeTestMutation();
  const [startInterestInventoryTest] = useStartInterestInventoryTestMutation();

  const contentData = trainingFinishedContent[type];

  const sideMenuValues = [
    { name: t("student.allAssessments"), path: paths.studentDashboard },
    {
      name: t("student.instructions"),
      path: `/test-instruction/${type}/${id}`,
    },
    { name: t("student.training"), path: `/training/${type}/${id}` },
  ];

  const handleStartTest = () => {
    if (type === "interest") {
      startInterestInventoryTest({
        variables: {
          id: Number(id),
        },
      })
        .then(() => history.push(`/student/test/${type}/${id}`))
        .catch((err) => Toast("error", err.message));
    } else {
      startAptitudeTest({
        variables: {
          id: Number(id),
        },
      })
        .then(() => history.push(`/student/test/${type}/${id}`))
        .catch((err) => Toast("error", err.message));
    }
  };

  return (
    <>
      {type !== "interest" && (
        <ImportantInfoModal
          isOpen={isInfoModalOpen}
          title={t("student.importantInfo")}
          onClose={() => setInfoModalOpen(false)}
          onTestStart={handleStartTest}
        />
      )}

      <TestInformation
        subHeader={t("student.wellDone")}
        contentType={contentData}
        assessmentType={type}
        sideMenuValues={sideMenuValues}
        buttonsSection={
          <ButtonsSection>
            <StyledButton
              variant="primary"
              onClick={
                type === "interest"
                  ? handleStartTest
                  : () => setInfoModalOpen(true)
              }
            >
              {t("student.startAssessment")}
            </StyledButton>
          </ButtonsSection>
        }
      />
    </>
  );
};

export default TrainingFinished;
