import React from "react";
import { useTranslation } from "react-i18next";
import {
  ContentContainer,
  IconContainer,
  TutorialCard,
  TutorialDescription,
  TutorialHeader,
  TutorialIcon,
  TutorialWrapper,
  ButtonContainer,
  PlayIcon,
  PlayButton,
  CloseIcon,
  TextContainer,
} from "./styles";
import { Props } from "./types";

const TutorialVideo = ({ navigateToVideo, handleClose }: Props) => {
  const { t } = useTranslation();

  return (
    <TutorialWrapper>
      <TutorialCard>
        <IconContainer>
          <TutorialIcon />
        </IconContainer>
        <ContentContainer>
          <TextContainer>
            <TutorialHeader>
              {t("student.welcome").toUpperCase()}
            </TutorialHeader>
            <TutorialDescription>
              {t("student.tutorialDescription")}
            </TutorialDescription>
          </TextContainer>
          <ButtonContainer>
            <PlayButton onClick={navigateToVideo}>
              <PlayIcon />
              PLAY
            </PlayButton>
          </ButtonContainer>
        </ContentContainer>
        <CloseIcon onClick={handleClose} />
      </TutorialCard>
    </TutorialWrapper>
  );
};

export default TutorialVideo;
