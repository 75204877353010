import styled, { css } from "styled-components";

import media from "helpers/media";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 500;
  flex: 1;

  ${media.SM`
      width: 100%;
  `};
`;

export const FlexBox = styled.div<{
  showScale?: boolean;
  selectedLength?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  &::after {
    position: absolute;
    width: 0;
    height: 4px;
    top: 9px;
    left: 13px;
    background-color: ${({ theme }) => theme.colors.scooter};
    z-index: 1;
    content: "";
    transition: all ease-in-out 300ms;
  }

  ${({ showScale }) =>
    showScale &&
    css`
      position: relative;
      &::before {
        position: absolute;
        width: 98%;
        height: 4px;
        top: 9px;
        left: 6px;
        background-color: ${({ theme }) => theme.colors.geyser};
        content: "";

        ${media.SM`
          width: 95%;
        `}
      }
    `}
  ${({ selectedLength }) =>
    selectedLength &&
    css`
      &::after {
        position: absolute;
        width: ${selectedLength}%;
        height: 4px;
        top: 9px;
        left: 13px;
        background-color: ${({ theme }) => theme.colors.scooter};
        z-index: 1;
        content: "";
        transition: all ease-in-out 300ms;
        ${media.SM`
          width: ${selectedLength - 3}%;
        `}
      }
    `}
`;

export const StyledInput = styled.input<{
  isSelected?: boolean;
  isCurrent?: boolean;
  isUncheckedHighlighted?: boolean;
  isPending?: boolean;
}>`
  appearance: none;
  z-index: 100;
  border: 3px solid ${({ theme }) => theme.colors.geyser};
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ isPending }) =>
    isPending &&
    css`
      cursor: progress;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.scooter};
    border-color: ${({ theme }) => theme.colors.scooter};

    &:after {
      content: "";
      display: block;
      border: 1px solid ${({ theme }) => theme.colors.scooter};
      width: 26px;
      height: 26px;
      border-radius: 100%;
      position: absolute;
      top: -7px;
      left: -7px;
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`background-color:${theme.colors.scooter}};
    border-color: ${theme.colors.scooter};
  `}

  ${({ isUncheckedHighlighted, theme }) =>
    isUncheckedHighlighted &&
    css`background-color:${theme.colors.mandy}};
  `}

  ${({ isCurrent, theme }) =>
    isCurrent &&
    css`
      &:after {
        content: "";
        display: block;
        border: 1px solid ${theme.colors.scooter};
        width: 26px;
        height: 26px;
        border-radius: 100%;
        position: absolute;
        top: -7px;
        left: -7px;
      }
    `}
`;

export const FlexItem = styled.div`
  min-width: 3rem;
`;
