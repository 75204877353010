import React, { FC, useState, useEffect } from "react";
import MathJax from "mathjax3-react";
import { useTranslation } from "react-i18next";

import TestProgressBar from "components/molecules/TestProgressBar";
import { useToast } from "hooks/Toast";

import {
  useGetAnswersForTestQuery,
  useGetQuestionsForCategoryQuery,
  useStoreAptitudeAnswerMutation,
} from "generated/graphql";

import { AssessmentType } from "types";

import Loader from "components/atoms/Loader";
import { questionsCountVar, answeredQuestionsCountVar } from "store/test";
import {
  QuestionsWrapper,
  QuestionNumber,
  Question,
  QuestionContent,
  AnswersContent,
  Answer,
  ProgressBarWrapper,
  StyledButton,
  AnswersWrapper,
  Spacer,
  ButtonWrapper,
} from "../styles";

const AptitudeQuestionCategoryType = {
  Numerical: "Numerical",
  Abstract: "Abstract",
  VerbalVocabulary: "Verbal_Vocabulary",
  VerbalComprehension: "Verbal_Comprehension",
  VerbalReasoning: "Verbal_Reasoning",
};

const Aptitude: FC<{
  type: AssessmentType;
  id: string;
  finishTest: VoidFunction;
}> = ({ type, id, finishTest }) => {
  const { t } = useTranslation();
  let apiCategory = AptitudeQuestionCategoryType.Numerical;
  switch (type) {
    case "abstract":
      apiCategory = AptitudeQuestionCategoryType.Abstract;
      break;
    case "numerical":
      apiCategory = AptitudeQuestionCategoryType.Numerical;
      break;
    case "verbal1":
      apiCategory = AptitudeQuestionCategoryType.VerbalVocabulary;
      break;
    case "verbal2":
      apiCategory = AptitudeQuestionCategoryType.VerbalComprehension;
      break;
    case "verbal3":
      apiCategory = AptitudeQuestionCategoryType.VerbalReasoning;
      break;
    default:
      apiCategory = AptitudeQuestionCategoryType.Numerical;
  }

  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [pendingAnswers, setPendingAnswers] = useState<{
    [key: string]: boolean;
  }>({});
  const Toast = useToast();

  const [storeAnswer] = useStoreAptitudeAnswerMutation();

  const {
    data: answersData,
    loading: answersLoading,
  } = useGetAnswersForTestQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: questionData,
    loading: questionDataLoading,
  } = useGetQuestionsForCategoryQuery({
    variables: {
      category: apiCategory,
    },
  });

  const selectAnswer = (question: string, answerId: string) => {
    setPendingAnswers((pending) => ({ ...pending, [question]: true }));
    storeAnswer({
      variables: {
        aptitudeTestId: Number(id),
        aptitudeQuestionId: Number(question),
        aptitudeAnswerId: Number(answerId),
      },
    })
      .then(() =>
        setAnswers((oldAnswers) => ({ ...oldAnswers, [question]: answerId }))
      )
      .catch((err) => Toast("error", err.message))
      .finally(() => {
        setPendingAnswers((pending) => ({ ...pending, [question]: false }));
      });
  };

  useEffect(
    () =>
      answersData?.aptitudeAnswers?.forEach(
        (answer) =>
          answer &&
          answer.answer &&
          answer.answer.id &&
          answer.question &&
          answer.question.id &&
          setAnswers((oldAnswers) => ({
            ...oldAnswers,
            [answer?.question?.id || ""]: answer?.answer?.id || "",
          }))
      ),
    [answersLoading, answersData]
  );

  useEffect(() => {
    questionsCountVar(questionData?.aptitudeQuestions?.length || 0);
    answeredQuestionsCountVar(Object.keys(answers).length);
  }, [questionData, answers]);

  const replaceImagePath = (htmlCode: string) => {
    return htmlCode.replaceAll(
      "../../storage/",
      "https://storage.googleapis.com/"
    );
  };

  if (questionDataLoading) return <Loader />;

  return (
    <MathJax.Provider url="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js">
      <QuestionsWrapper>
        {questionData &&
          questionData.aptitudeQuestions &&
          questionData.aptitudeQuestions
            .slice()
            .sort((x, y) => ((x?.order || 0) > (y?.order || 0) ? 1 : -1))
            .map((question, index) => {
              return (
                <Question key={question?.id}>
                  <QuestionNumber>Q{index + 1}</QuestionNumber>
                  <QuestionContent>
                    {question && question.questionText && (
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: replaceImagePath(question.questionText),
                        }}
                      />
                    )}
                  </QuestionContent>

                  <AnswersWrapper pending={pendingAnswers[question?.id || ""]}>
                    <AnswersContent type={type}>
                      {question
                        ?.possibleAnswers!.slice()
                        .sort((x, y) =>
                          (x?.key || 0) > (y?.key || 0) ? 1 : -1
                        )
                        .map((answer, idx) => {
                          const pending = pendingAnswers[question?.id || ""];
                          const selected = answers[question.id] === answer?.id;

                          return (
                            <Answer
                              key={idx}
                              notInteractive={pending}
                              onClick={
                                pending || selected
                                  ? undefined
                                  : () => selectAnswer(question?.id, answer?.id)
                              }
                              selected={selected}
                            >
                              {answer && answer.text && (
                                <div
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: replaceImagePath(answer.text),
                                  }}
                                />
                              )}
                            </Answer>
                          );
                        })}
                    </AnswersContent>
                    <Spacer />
                  </AnswersWrapper>
                </Question>
              );
            })}
        <ButtonWrapper>
          <StyledButton variant="primary" onClick={finishTest}>
            {t("student.finishAssessment")}
          </StyledButton>
        </ButtonWrapper>
      </QuestionsWrapper>
      <ProgressBarWrapper>
        <TestProgressBar
          completed={Object.keys(answers).length}
          allQuestions={questionData?.aptitudeQuestions?.length || 0}
        />
      </ProgressBarWrapper>
    </MathJax.Provider>
  );
};

export default Aptitude;
