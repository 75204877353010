import media from "helpers/media";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 0 14rem;
  align-items: center;
  display: flex;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.5rem;

  ${media.SM`
    display: none;
  `};
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.denim};
  padding: 0 2.4rem;
`;

export const Dot = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.denim};
`;
