import React, { FC } from "react";

import styled, { css } from "styled-components";

import { ReactComponent as Logo } from "assets/images/logo.svg";
import ThumbsUpImage from "assets/images/thumbs-up-image.png";

import media from "helpers/media";

const ColumnWrapper = styled.div`
  display: flex;
  ${media.SM`
    flex-direction: column;
  `}
`;

const SideColumn = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 474px;
  padding: 4.8rem;
  display: flex;
  flex-direction: column;

  ${media.SM`
    width: 100%;
    padding: 0;
  `}
`;

const MainColumn = styled.div`
  background-color: ${({ theme }) => theme.colors.denim};
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  ${({ theme }) => css`
    ${media.SM`
      padding: 1rem; 
      background-color: ${theme.colors.white};
      height: auto;
  `}
  `}
`;

const WelcomeWrapper = styled.div`
  padding: 1.6rem;
  flex: 1;
  display: flex;
  ${({ theme }) => css`
    ${media.SM`
      background-color: ${theme.colors.whiteLilac};
      margin-top: 1rem;
  `}
  `}
`;

const WelcomeText = styled.div`
  font-size: 2.8rem;
  line-height: 4.8rem;
  font-weight: bold;
  align-self: center;
  font-size: 2.8rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  ${media.SM`
    flex-direction: row-reverse;
    align-items: center;
    margin: auto;
  `}
`;

const Emphasis = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.denim};
  font-size: 2.8rem;

  ${media.SM`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}
`;

const CaptionText = styled.h2`
  margin-top: 1rem;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 4.8rem;
  letter-spacing: 0.03rem;
  color: ${({ theme }) => theme.colors.mineShaft};

  ${media.SM`
    font-size: 1.4rem;
    line-height: 2.4rem;
    width: 16rem;
  `}
`;

const FooterLinks = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  padding-top: 2.4rem;
  color: ${({ theme }) => theme.colors.denim};
  font-size: 1.5rem;
  line-height: 2.2rem;
  ${media.SM`
    display: none;
  `}
`;

const FooterLink = styled.a`
  margin-right: 2.4rem;
  display: inline-block;

  &::after {
    content: "•";
    margin-left: 2.4rem;
    display: inline-block;
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
`;

const ContentWrapper = styled.div<{ narrow: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 64.2rem;
  border-radius: 0.8rem;
  padding: 4.8rem 6rem;
  margin: 4.8rem 6rem;

  flex: 1;
  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 45rem;
    `}
  ${media.SM`
    margin-top: 0;
    margin: 0;
    padding: 0 4rem;
    flex: 1;
    display: flex;
  `}
`;

const LogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${media.SM`
    align-self: center;
    margin-top: 2.5rem;
  `}
`;

const StyledThumbsUpImage = styled.img`
  width: auto;
  height: 24rem;

  ${media.SM`
    width: auto;
    height: 14rem;
  `}
`;

type Props = {
  selfRegistration?: boolean;
  narrow?: boolean;
};

const LoginTheme: FC<Props> = ({
  children,
  selfRegistration = false,
  narrow = false,
}) => (
  <ColumnWrapper>
    <SideColumn>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <WelcomeWrapper>
        <WelcomeText>
          <div>
            <StyledThumbsUpImage src={ThumbsUpImage} />
          </div>
          <CaptionText>
            {selfRegistration ? (
              <>Take the official assessment </>
            ) : (
              <>
                Join the students <br />
                from your school <br />
              </>
            )}
            and find <Emphasis>the right career</Emphasis> for yourself
          </CaptionText>
        </WelcomeText>
      </WelcomeWrapper>
      <FooterLinks>
        <FooterLink href="http://www.careerfit.com">CareerFit.com</FooterLink>
      </FooterLinks>
    </SideColumn>
    <MainColumn>
      <ContentWrapper narrow={narrow}>{children}</ContentWrapper>
    </MainColumn>
  </ColumnWrapper>
);

export default LoginTheme;
