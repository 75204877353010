import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { ReactComponent as GraduationCap } from "assets/images/graduation-cap.svg";
import { ReactComponent as ReportIcon } from "assets/images/report.svg";

import Loader from "components/atoms/Loader";
import Title from "components/atoms/Title";
import Assessment from "components/molecules/Assessment";
import StudentInfo from "components/molecules/StudentInfo";

import { downloadStudentReport } from "api/downloadReport";
import {
  useCloseTutorialVideoMutation,
  useFormSubmitByAuthedUserMutation,
  useGetAllAptitudeTestsQuery,
  useGetAllSchoolsQuery,
  useGetAuthenticatedUserQuery,
  useGetFeedbackFormWithResponseLazyQuery,
  useGetVideosWithViewsQuery,
} from "generated/graphql";
import {
  ReportContainer,
  ReportTop,
  ReportDownloadText,
  ReportBadge,
} from "components/molecules/StudentInfo/styles";
import { ApiAssessment, AssessmentStatus } from "types";
import Button from "components/atoms/Button";
import { useToast } from "hooks/Toast";

import { currentSchoolIdVar } from "store/school";
import useClickOutside from "hooks/clickOutSide";

import SchoolDropdown from "components/molecules/SchoolDropdown";
import { student } from "./data";
import {
  Container,
  Muted,
  Content,
  SchoolLabel,
  TopContainer,
  AssessmentList,
  MobileHeader,
  DesktopOnly,
  MobileOnly,
} from "./styles";
import TutorialVideo from "../../../components/molecules/TutorialVideo";
import { VideoModel } from "../Academy/AcademyDashboard/types";
import FeedbackFormModal from "./Modals/FeedbackFormModal";
import { FeedbackFormWithResponses } from "./Modals/FeedbackFormModal/types";

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useGetAllAptitudeTestsQuery({
    fetchPolicy: "network-only",
  });
  const { data: userData } = useGetAuthenticatedUserQuery();
  const { data: schoolData } = useGetAllSchoolsQuery();
  const { data: videoData, refetch: refetchVideo } = useGetVideosWithViewsQuery(
    {
      variables: { isTutorial: true },
      fetchPolicy: "cache-and-network",
    }
  );
  const [
    findFeedbackFormQuery,
    {
      loading: loadingFindFeedbacFormkQuery,
      called: findFeedbackFormCalled,
      data: feedbackFormData,
    },
  ] = useGetFeedbackFormWithResponseLazyQuery();

  const [closeTutorialVideo] = useCloseTutorialVideoMutation();
  const [studentId, setStudentId] = useState<string | null>(null);
  const [tutorialVideo, setTutorialVideo] = useState<VideoModel | null>(null);
  const [
    isFeedbackFormModalVisible,
    setIsFeedbackFormModalVisible,
  ] = useState<boolean>(false);
  const [
    feedbackForm,
    setFeedbackForm,
  ] = useState<FeedbackFormWithResponses | null>(null);
  const [formSubmit] = useFormSubmitByAuthedUserMutation();

  const Toast = useToast();

  useEffect(() => {
    const tutorialData = videoData?.findVideosWithViews as VideoModel[];
    if (tutorialData?.length) {
      setTutorialVideo(tutorialData[0]);
    }
  }, [videoData]);

  const {
    visible: dropdownVisible,
    setVisible: setDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const assessments: ApiAssessment[] = student.assessments.map((assessment) => {
    const apiData = data?.aptitudeTestAttempts?.find(
      (test) => test?.category === assessment.apiName
    ) || {
      id: userData?.authenticatedItem?.students![0]?.testAttempt?.id || "-1",
      isCompleted:
        userData?.authenticatedItem?.students![0]?.testAttempt
          ?.isInterestInventoryCompleted || false,
      startTime: null,
    };

    let status: AssessmentStatus = apiData?.isCompleted
      ? "finished"
      : "available";

    if (assessment.type === "verbal2") {
      const vocabulary = data?.aptitudeTestAttempts?.find(
        (test) => test?.category === "Verbal_Vocabulary"
      );
      if (!vocabulary?.isCompleted) status = "not-available";
    }

    if (assessment.type === "verbal3") {
      const vocabulary = data?.aptitudeTestAttempts?.find(
        (test) => test?.category === "Verbal_Comprehension"
      );
      if (!vocabulary?.isCompleted) status = "not-available";
    }

    return {
      ...assessment,
      status,
      ...apiData,
    };
  });

  const activeAssessment = assessments.find(
    (assessment) => !assessment.isCompleted && assessment.startTime
  );

  const handleDownloadReport = () => {
    if (studentId) {
      downloadStudentReport(
        studentId,
        (res) => {
          fileDownload(
            res.data,
            userData?.authenticatedItem?.students![0].testAttempt?.reportName ||
              ""
          );
          Toast("success", t("successAction.reportDownloading"));
        },
        () => {
          Toast("error", t("errorAction.reportDoesntExist"));
        }
      );
    }
  };

  const handleNavigateToVideo = () => {
    if (tutorialVideo) {
      history.push(`/tutorial/watch/${tutorialVideo.id}`);
    }
  };

  const setCurrentSchoolId = (id: string) => {
    currentSchoolIdVar(id);
    localStorage.setItem("CF_currentSchool", id);
  };

  useEffect(() => {
    if (schoolData && schoolData.schools) {
      const lastSchool = schoolData.schools[schoolData.schools.length - 1];
      const id = userData?.authenticatedItem?.students?.reduce((acc, curr) => {
        if (curr.school?.id === lastSchool?.id) {
          return curr.id;
        }
        return acc;
      }, "");

      setCurrentSchoolId(lastSchool?.id as string);
      setStudentId(id as string);
    }
  }, [schoolData, userData]);

  useEffect(() => {
    const isTestCompleted = userData?.authenticatedItem?.students![0]
      .testAttempt?.isCompleted;

    if (isTestCompleted && !findFeedbackFormCalled) {
      findFeedbackFormQuery({
        variables: {
          formType: "ASSESSMENT",
        },
      });
    }

    const feedbackFormWithResponse =
      feedbackFormData?.findFeedbackFormWithResponse;

    const formClosedInThisSession = localStorage.getItem(
      "CF_FeedbackForm_Closed"
    );

    if (feedbackFormWithResponse) {
      setFeedbackForm(
        (feedbackFormWithResponse as unknown) as FeedbackFormWithResponses
      );
    }

    const feedbackSubmitted = feedbackForm?.responses.find(
      (response) => response.isSubmitted
    );

    if (
      !formClosedInThisSession &&
      isTestCompleted &&
      !feedbackSubmitted &&
      !loadingFindFeedbacFormkQuery &&
      feedbackForm
    ) {
      setIsFeedbackFormModalVisible(true);
    }
  }, [
    userData,
    findFeedbackFormQuery,
    loadingFindFeedbacFormkQuery,
    findFeedbackFormCalled,
    feedbackFormData,
    feedbackForm,
  ]);

  const toggleDropdownVisible = () => setDropdownVisible(!dropdownVisible);

  const handleClose = () => {
    if (tutorialVideo) {
      closeTutorialVideo({
        variables: {
          videoId: Number(tutorialVideo.id),
        },
      }).then(() => {
        refetchVideo();
      });
    }
  };

  if (activeAssessment) {
    return (
      <Redirect
        to={`/student/test/${activeAssessment.type}/${activeAssessment.id}`}
      />
    );
  }

  const isReportToDownload =
    userData?.authenticatedItem?.students![0]?.testAttempt?.isCompleted &&
    userData?.authenticatedItem?.students![0]?.testAttempt?.isReportPublished &&
    typeof userData?.authenticatedItem?.students![0]?.testAttempt
      ?.reportName === "string";

  const isTutorialVideoVisible =
    tutorialVideo && !tutorialVideo.views[0]?.isCompleted;

  const isTutorialButtonVisible = !!(
    tutorialVideo && tutorialVideo.views[0]?.isCompleted
  );

  const onFeedbackFormModalClose = () => {
    localStorage.setItem("CF_FeedbackForm_Closed", "true");
    setIsFeedbackFormModalVisible(false);
  };

  const onFeedbackFormSubmit = (responseId: string) => {
    if (feedbackForm) {
      formSubmit({
        variables: {
          formId: feedbackForm.formId,
          responseId,
          testAttemptId:
            Number(userData?.authenticatedItem?.students![0].testAttempt?.id) ||
            -1,
        },
      })
        .then(() => {
          Toast("success", t("student.feedbackThank"));
          setIsFeedbackFormModalVisible(false);
        })
        .catch((err) => Toast("error", err.message));
    }
  };

  if (loading) return <Loader />;

  return (
    <Container>
      <TopContainer ref={containerRef}>
        <FeedbackFormModal
          isVisible={isFeedbackFormModalVisible}
          onClose={onFeedbackFormModalClose}
          testAttemptId={
            userData?.authenticatedItem?.students![0].testAttempt?.id || "-1"
          }
          formData={feedbackForm}
          onSubmit={onFeedbackFormSubmit}
        />
        <Title style={{ marginBottom: "0" }}>
          <Muted>{t("user.hi")},</Muted>{" "}
          {userData?.authenticatedItem?.firstName || ""}!
        </Title>
        {userData?.authenticatedItem?.teachers![0] &&
        userData?.authenticatedItem?.teachers[0].school?.isIndividual ? (
          <></>
        ) : (
          <SchoolLabel onClick={toggleDropdownVisible}>
            <GraduationCap style={{ width: "6rem", marginRight: "0.6rem" }} />
            <SchoolDropdown
              visible={dropdownVisible}
              onSelect={setCurrentSchoolId}
              context="student"
            />
          </SchoolLabel>
        )}
      </TopContainer>
      <Content>
        <DesktopOnly>
          <StudentInfo
            welcomeText={t("student.availableAssessments")}
            finished={!!isReportToDownload}
            studentId={studentId || ""}
            reportName={
              userData?.authenticatedItem?.students![0]?.testAttempt
                ?.reportName || ""
            }
            navigateToVideo={handleNavigateToVideo}
            isTutorialButtonVisible={isTutorialButtonVisible}
          />
        </DesktopOnly>
        <AssessmentList>
          <MobileOnly>
            {isReportToDownload ? (
              <ReportContainer>
                <ReportTop>
                  {t("student.reportIsReady")}
                  <ReportBadge>
                    <ReportIcon />
                  </ReportBadge>
                </ReportTop>
                <ReportDownloadText>
                  {t("student.downloadReport")}
                </ReportDownloadText>

                <Button variant="primary" onClick={handleDownloadReport}>
                  {t("student.downloadYourReport")}
                </Button>
              </ReportContainer>
            ) : (
              <MobileHeader>{t("student.assessments")}</MobileHeader>
            )}
          </MobileOnly>
          {isTutorialVideoVisible && (
            <TutorialVideo
              navigateToVideo={handleNavigateToVideo}
              handleClose={handleClose}
            />
          )}
          {assessments.map((assessment, index) => (
            <Assessment key={index} {...assessment} />
          ))}
        </AssessmentList>
      </Content>
    </Container>
  );
};

export default Dashboard;
