import React, { FC } from "react";

import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { ReactComponent as ReportIcon } from "assets/images/report.svg";
import Button from "components/atoms/Button";

import { downloadStudentReport } from "api/downloadReport";

import { useToast } from "hooks/Toast";
import {
  FaqIcon,
  ExternalLinkIcon,
  ButtonContainer,
  InfoContainer,
  AssessmentInfo,
  AvailableAssessments,
  RoundedButton,
  ReportContainer,
  ReportTop,
  ReportBadge,
  ReportDownloadText,
  MobileOnly,
  TutorialIcon,
} from "./styles";

import { Props } from "./types";

const StudentInfo: FC<Props> = ({
  reportName,
  welcomeText,
  finished,
  studentId,
  navigateToVideo,
  isTutorialButtonVisible,
}) => {
  const { t } = useTranslation();
  const Toast = useToast();
  const handleDownloadReport = (id: string) => {
    downloadStudentReport(
      id,
      (res) => {
        fileDownload(res.data, reportName);
        Toast("success", t("successAction.reportDownloading"));
      },
      () => {
        Toast("error", t("errorAction.reportDoesntExist"));
      }
    );
  };

  const handleFaq = () => {
    window.open("https://careerfit.com/faq", "_blank");
  };

  return (
    <InfoContainer>
      {finished ? (
        <>
          <MobileOnly>{t("student.reportReady")}</MobileOnly>
          <ReportContainer>
            <ReportTop>
              {t("student.reportIsReady")}
              <ReportBadge>
                <ReportIcon />
              </ReportBadge>
            </ReportTop>
            <ReportDownloadText>
              {t("student.downloadReport")}
            </ReportDownloadText>

            <Button
              variant="primary"
              onClick={() => handleDownloadReport(studentId || "")}
            >
              {t("student.downloadYourReport")}
            </Button>
          </ReportContainer>
        </>
      ) : (
        <>
          <AvailableAssessments>{welcomeText}</AvailableAssessments>
          <AssessmentInfo>
            {t("student.shareReportInfo_1")}
            <br />
            <br />
            {t("student.shareReportInfo_2")}
            <br />
            <br />
            {t("student.shareReportInfo_3")}
            <br />
            <br />
            {t("student.shareReportInfo_4")}
            <br />
            <br />
            {t("student.shareReportInfo_5")}
          </AssessmentInfo>
        </>
      )}

      {isTutorialButtonVisible && (
        <ButtonContainer>
          <RoundedButton isActive onClick={navigateToVideo}>
            <TutorialIcon />
          </RoundedButton>
          <span>{t("student.tutorialWatch")}</span>
        </ButtonContainer>
      )}
      <ButtonContainer>
        <RoundedButton isActive={!finished} onClick={handleFaq}>
          <FaqIcon $isActive={!finished} />
        </RoundedButton>
        <span>
          {t("student.readFaq")}
          <ExternalLinkIcon />
        </span>
      </ButtonContainer>
    </InfoContainer>
  );
};

export default StudentInfo;
