import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import paths from "routes/paths";
import { AssessmentType } from "types";
import TestInformation from "components/molecules/TestInformation";
import { useToast } from "hooks/Toast";
import {
  useStartAptitudeTestMutation,
  useStartInterestInventoryTestMutation,
} from "generated/graphql";

import { instructionsContent } from "./data";
import ImportantInfoModal from "../Modals/ImportantInfoModal";
import { StyledButton, ButtonsSection } from "../TrainingFinished/styles";

const Instructions: FC = () => {
  const { t } = useTranslation();
  const { type, id } = useParams<{ type: AssessmentType; id: string }>();
  const history = useHistory();
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const Toast = useToast();

  const [startAptitudeTest] = useStartAptitudeTestMutation();
  const [startInterestInventoryTest] = useStartInterestInventoryTestMutation();

  const contentData = instructionsContent[type];

  const sideMenuValues = [
    { name: t("student.allAssessments"), path: paths.studentDashboard },
  ];

  const handleNavigate = () => {
    history.push(`/training/${type}/${id}`);
  };

  const handleStartTest = () => {
    if (type === "interest") {
      startInterestInventoryTest({
        variables: {
          id: Number(id),
        },
      })
        .then(() => history.push(`/student/test/${type}/${id}`))
        .catch((err) => Toast("error", err.message));
    } else {
      startAptitudeTest({
        variables: {
          id: Number(id),
        },
      })
        .then(() => history.push(`/student/test/${type}/${id}`))
        .catch((err) => Toast("error", err.message));
    }
  };

  return (
    <>
      {type !== "interest" && (
        <ImportantInfoModal
          isOpen={isInfoModalOpen}
          onTestStart={handleStartTest}
          title={t("student.importantInfo")}
          onClose={() => setInfoModalOpen(false)}
        />
      )}
      <TestInformation
        subHeader={t("student.instructions")}
        contentType={contentData}
        assessmentType={type}
        sideMenuValues={sideMenuValues}
        buttonsSection={
          <ButtonsSection>
            <StyledButton variant="primary" onClick={handleNavigate}>
              {t("student.exampleQuestions")}
            </StyledButton>
            <StyledButton
              variant="secondary"
              onClick={
                type === "interest"
                  ? handleStartTest
                  : () => setInfoModalOpen(true)
              }
            >
              {t("student.startAssessment")}
            </StyledButton>
          </ButtonsSection>
        }
      />
    </>
  );
};

export default Instructions;
