import styled, { keyframes, css } from "styled-components";

import media from "helpers/media";
import Clock from "assets/images/clock-white.svg";

const rotate = keyframes`
  from {transform: scale(1, 1) rotate(-5deg);}
    to {transform: scale(1.2, 1.2) rotate(5deg);}
`;

export const CircleClock = styled.div<{ animate?: boolean }>`
  background-color: ${({ theme }) => theme.colors.defaultColor};
  width: 14.9rem;
  zoom: 1;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${rotate} 200ms linear infinite;
    `};
  transition: zoom ease-in 0.4s;
  height: 14.9rem;
  border-radius: 14.9rem;
  margin-top: -3rem;
  color: ${({ theme }) => theme.colors.white};
  background-image: url(${Clock});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.3rem;

  ${media.SM`
    width: 8rem;
    height: 8rem;
    margin-top: -5rem;
    margin-right: 2.4rem;
    font-size: 1.2rem;
    background-size: 2.2rem;
    padding-top: 4.2rem
  `};
`;
