import { getTestDuration } from "helpers/getTestDuration";
import { Assessment, Student } from "types";
import i18n from "utils/i18n/i18n";

export const assessments: Assessment[] = [
  {
    name: i18n.t("student.verbalVocabulary"),
    status: "available",
    time: getTestDuration("verbal1").toString(),
    type: "verbal1",
    apiName: "Verbal_Vocabulary",
  },
  {
    name: i18n.t("student.numericalReasoning"),
    status: "available",
    time: getTestDuration("numerical").toString(),
    type: "numerical",
    apiName: "Numerical",
  },
  {
    name: i18n.t("student.verbalComprehension"),
    status: "not-available",
    time: getTestDuration("verbal2").toString(),
    type: "verbal2",
    apiName: "Verbal_Comprehension",
  },
  {
    name: i18n.t("student.abstractInventory"),
    status: "available",
    time: getTestDuration("abstract").toString(),
    type: "abstract",
    apiName: "Abstract",
  },
  {
    name: i18n.t("student.verbalReasoning"),
    status: "not-available",
    time: getTestDuration("verbal3").toString(),
    type: "verbal3",
    apiName: "Verbal_Reasoning",
  },
  {
    name: i18n.t("student.interestInventory"),
    status: "available",
    time: "",
    type: "interest",
    apiName: "Interest",
  },
];

export const student: Student = {
  name: "Elin",
  assessments,
  assessments_finished: false,
};

export const studentFinished: Student = {
  name: "Elin",
  assessments,
  assessments_finished: true,
};
