import React, { FC, CSSProperties } from "react";
import Select, { ValueType } from "react-select";

import Label from "components/atoms/Label";

import { SelectWrapper, Error } from "./styles";
import { Props, InputProps, OptionType } from "./types";

export type SelectValueType = ValueType<OptionType, false>;

export const styles = {
  control: (base: CSSProperties, { isDisabled }: InputProps) => {
    return {
      ...base,
      boxShadow: "none",
      outline: "none",
      height: "100%",
      fontSize: "1.6rem",
      border: "1px solid #DFE0EB",
      backgroundColor: !isDisabled ? "#FFFFFF" : "#DFE0EB",
    };
  },
  indicatorSeparator: (base: CSSProperties) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    transition: "all 200ms ease",
  }),
  option: (base: CSSProperties, { isSelected, isFocused }: InputProps) => {
    return {
      ...base,
      minHeight: "40px",
      fontSize: "1.6rem",
      color: isSelected && !isFocused ? "#ffffff" : "#252733",
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isFocused ? "#d8ddf2" : isSelected ? "#1B75BB" : "none",
    };
  },
  singleValue: (base: CSSProperties) => ({
    ...base,
    paddingLeft: "4px",
    fontSize: "1.6rem",
    color: "#252733",
  }),
};

const StyledSelect: FC<Props> = ({
  label,
  options,
  name,
  value,
  handleChange,
  handleBlur,
  error,
  menuPlacement,
  disabled = false,
}) => {
  const onChange = (val: SelectValueType) => {
    handleChange(name, val);
  };

  const onBlur = () => {
    if (handleBlur) {
      handleBlur(name, true);
    }
  };

  return (
    <SelectWrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        options={options}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        styles={styles}
        isSearchable={false}
        menuPlacement={menuPlacement}
      />

      {error && <Error>{error}</Error>}
    </SelectWrapper>
  );
};

export default StyledSelect;
