import styled, { css } from "styled-components";

const Button = styled.button<{
  variant: "primary" | "link" | "secondary" | "tertiary" | "text" | "danger";
  width?: string;
  disabled?: boolean;
}>`
  padding: 0.5rem 2.5rem;
  background: ${({ theme }) => theme.colors.denim};
  border-radius: 2.5rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.02rem;
  height: 4.8rem;
  min-width: 15rem;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  width: ${({ width }) => width || "100%"};

  &:hover {
    background-color: ${({ theme }) => theme.colors.matisse};
  }

  ${({ variant }) =>
    variant === "link" &&
    css`
      background: none;
      color: ${({ theme }) => theme.colors.denim};
      box-shadow: none;
      border-radius: none;

      &:hover {
        color: ${({ theme }) => theme.colors.treePoppy};
        background: transparent;
      }
    `}

  ${({ variant }) =>
    variant === "danger" &&
    css`
      color: ${({ theme }) => theme.colors.mandy};
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.mandy};

      &:hover {
        background-color: ${({ theme }) => theme.colors.amour};
      }
    `}

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.denim};
      border: ${({ theme }) => `1px solid ${theme.colors.blueHaze}`};

      &:hover {
        background-color: ${({ theme }) => theme.colors.aliceBlue};
      }
    `}
     ${({ variant }) =>
    variant === "tertiary" &&
    css`
      background: transparent;
      color: ${({ theme }) => theme.colors.denim};
      border: ${({ theme }) => `1px solid ${theme.colors.denim}`};

      &:hover {
        background-color: ${({ theme }) => theme.colors.aliceBlue};
      }
    `}
    ${({ variant, disabled }) =>
    variant === "text" &&
    css`
      background: none;
      color: ${({ theme }) =>
        disabled ? theme.colors.hitGray : theme.colors.sharade};
      box-shadow: none;
      border-radius: none;
      height: auto;
      padding: 0;
      min-width: auto;
      font-size: 1.4rem;
      padding: 0 0.6rem;

      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => disabled && theme.colors.hitGray};
      cursor: not-allowed;
      pointer-events: all !important;
    `}
`;

export default Button;
