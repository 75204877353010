// INFO: Color names are generated from here: https://chir.ag/projects/name-that-color/

export enum Colors {
  astra = "#FBE9BE",
  aliceBlue = "#F5FAFF",
  blueHaze = "#BFC5DD",
  treePoppy = "#F6921E",
  mineShaft = "#333333",
  stormGray = "#6E707C",
  whiteLilac = "#F6F7FB",
  white = "#FFFFFF",
  black = "#000000",
  lavenderGray = "#B7BDD7",
  blackSqueeze = "#E5EFF6",
  denim = "#1B75BB",
  athensGrayLight = "#EAEAEC",
  athensGray = "#DFE0EB",
  sharade = "#252733",
  mishka = "#D4D8E1",
  newYorkPink = "#DB838C",
  mercury = "#e5e5e5",
  matisse = "#15598F",
  mystic = "#DDE3E9",
  manatee = "#96979E",
  nepal = "#94adc2",
  oceanGreen = "#3FB47C",
  geyser = "#CFD8DC",
  flamePea = "#E25A3B",
  saffron = "#F3CB3C",
  shuttleGray = "#59626C",
  aquaHaze = "#F7F9FA",
  tulipTree = "#E8C239",
  mandy = "#EB636B",
  hitGray = "#A8B4BA",
  linkWater = "#DFEDF8",
  linkWaterDark = "#C8DFF1",
  linkWaterDim = "#D8DDF2",
  kobi = "#EAB5CC",
  mulberry = "#C94780",
  sail = "#B5D4F8",
  pictonBlue = "#4595ED",
  pictonBlueLight = "#4CD3E6",
  solitude = "#E0EEFF",
  iceCold = "#B7EDF5",
  linkWaterLight = "#D1E3F1",
  calypso = "#367797",
  amour = "#FAEDF3",
  spindle = "#C0D9ED",
  scooter = "#33BACC",
  hummingBird = "#E8FAFC",
  fuelYellow = "#ECB51B",
  santasGray = "#A3A4AC",
}

export default Colors;
