import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import Input from "components/molecules/Input";
import ConfirmationModal from "components/molecules/ConfirmationModal";

import { firstName, lastName } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import {
  useGetAuthenticatedUserQuery,
  useRemoveAccountMutation,
  useUpdateUserMutation,
} from "generated/graphql";

import {
  ButtonSection,
  DeleteAccount,
  DeleteAccountWrapper,
  StyledButton,
} from "./styles";

type EditUserProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  title: string;
  onUserDataChange: VoidFunction;
};

const EditUserModal: FC<EditUserProps> = ({
  isOpen,
  onClose,
  title,
  onUserDataChange,
}) => {
  const { t } = useTranslation();
  const [editUser] = useUpdateUserMutation();
  const { data: userData } = useGetAuthenticatedUserQuery();

  const editUserInitialValues = {
    firstName: userData?.authenticatedItem?.firstName || "",
    lastName: userData?.authenticatedItem?.lastName || "",
  };

  const Toast = useToast();

  const editUserForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: editUserInitialValues,
    validationSchema: Yup.object({
      firstName,
      lastName,
    }),

    onSubmit: async (values) => {
      if (userData && userData.authenticatedItem) {
        editUser({
          variables: {
            id: userData.authenticatedItem.id,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        })
          .then(() => {
            onClose();
            Toast("success", t("successAction.userEdited"));
            onUserDataChange();
          })
          .catch((e) => {
            Toast("error", e.message || t("errorAction.generalError"));
          })
          .finally(() => {
            onClose();
          });
      }
    },
  });

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const history = useHistory();
  const [removeAccount] = useRemoveAccountMutation({
    variables: { id: Number(userData?.authenticatedItem?.id) || -1 },
  });
  const deleteAccount = () => {
    removeAccount().then(() => {
      localStorage.removeItem("CF_token");

      history.push("/login");
    });
  };

  const openConfirmModal = () => setIsConfirmModalOpened(true);
  const closeConfirmModal = () => setIsConfirmModalOpened(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        size="narrow"
        headerMargin="0 0 4.8rem 0"
      >
        <form
          onSubmit={editUserForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="firstName"
            label={t("global.firstNameLabel")}
            placeholder={t("global.firstNamePlaceholder")}
            handleChange={editUserForm.handleChange}
            handleBlur={editUserForm.handleBlur}
            value={editUserForm.values.firstName}
            error={
              editUserForm.touched.firstName && editUserForm.errors.firstName
            }
          />
          <Input
            name="lastName"
            label={t("global.lastNameLabel")}
            placeholder={t("global.lastNamePlaceholder")}
            handleChange={editUserForm.handleChange}
            handleBlur={editUserForm.handleBlur}
            value={editUserForm.values.lastName}
            error={
              editUserForm.touched.lastName && editUserForm.errors.lastName
            }
          />

          <ButtonSection>
            <Button
              variant="primary"
              type="button"
              onClick={() => editUserForm.submitForm()}
            >
              {t("actions.save")}
            </Button>
            <Button variant="link" type="button" onClick={onClose}>
              {t("actions.cancel")}
            </Button>
          </ButtonSection>
        </form>
        <DeleteAccountWrapper>
          <DeleteAccount onClick={openConfirmModal}>
            {t("user.deleteAccount")}
          </DeleteAccount>
        </DeleteAccountWrapper>
      </Modal>
      <ConfirmationModal
        title={t("teacher.deleteAccount")}
        isOpen={isConfirmModalOpened}
        onClose={closeConfirmModal}
        contentText={t("user.sureAboutRemoval")}
        confirmButton={
          <div>
            <StyledButton variant="danger" onClick={deleteAccount}>
              {t("user.confirmRemoval")}
            </StyledButton>
          </div>
        }
        cancelButton={
          <Button variant="link" onClick={closeConfirmModal}>
            {t("actions.cancel")}
          </Button>
        }
      />
    </>
  );
};

export default EditUserModal;
